import { useEffect, useMemo, useRef, useState } from 'react';
import { M70MediaItem } from '~/lib/data-contract';
import { Carousel, Modal, Text, Image, useTracking, ImageProps } from '~/shared/components';
import { useGalleryModal } from '~/shared/hooks/useGalleryModal/useGalleryModalState';
import {
    StyledButtonWrapper,
    StyledCaptionWrapper,
    StyledGalleryItem,
    StyledGalleryItemInner,
    StyledGalleryModal,
    StyledImageRatio,
    StyledImageWrapper,
    StyledVideoInnerWrapper,
    StyledVideoWrapper,
} from '../styles';
import SwiperCore from 'swiper';
import { weakKey } from '~/shared/utils/jsx';
import { useTheme } from '@emotion/react';
import { useDebounce, useWindowSize } from 'react-use';
import { useSwiperHelper } from '~/shared/hooks/useSwiperHelper/useSwiperHelper';
import { CarouselButton } from '../../Carousel/components/CarouselButton/CarouselButton';
import dynamic from 'next/dynamic';

const VideoPlayerLazy = dynamic(() => import('react-player/lazy'));
export type ModalGalleryItem = M70MediaItem & {
    image: M70MediaItem['image'] & ImageProps;
};

export type ModalGalleryProps = {
    items: ModalGalleryItem[];
};

export const ModalGalleryComponent = ({ items }: ModalGalleryProps) => {
    const { trackMedia } = useTracking();
    const { colors } = useTheme();
    const videoContainerRef = useRef<HTMLDivElement>(null);
    const { hide, visible, activeMediaId } = useGalleryModal();
    const [swiper, setSwiper] = useState<SwiperCore>();
    const [width, setWidth] = useState<number>(0);
    const [height, setHeight] = useState<number>(0);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const prevRef = useRef<HTMLButtonElement>(null);
    const nextRef = useRef<HTMLButtonElement>(null);

    const { slideNext, slidePrev, hasNext, hasPrev } = useSwiperHelper(swiper);

    const keyboardActions = (event: KeyboardEvent) => {
        switch (event.key) {
            case 'Escape':
                hide();
                break;
            case 'ArrowLeft':
                swiper?.slidePrev();
                break;
            case 'ArrowRight':
                swiper?.slideNext();
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (swiper) {
            document.addEventListener('keydown', keyboardActions, false);
        }

        return () => document.removeEventListener('keydown', keyboardActions, false);
    }, [swiper]);

    useEffect(() => {
        if (activeMediaId) {
            trackMedia(items.find((item) => item.id === activeMediaId));
        }
    }, [activeMediaId]);

    useEffect(() => {
        const scaleVideo = () => {
            requestAnimationFrame(() => {
                const aspectElement = videoContainerRef?.current;

                if (aspectElement) {
                    const parentElement = aspectElement.parentElement as HTMLDivElement;
                    const { width, height } = parentElement.getBoundingClientRect();
                    const standardRatio = 16 / 9;

                    const w = width > 1280 ? 1280 : width;
                    const h = height > 720 ? 720 : height;

                    if (w > h) {
                        setWidth(w);
                        setHeight(w / standardRatio);
                    } else {
                        setWidth(h * standardRatio);
                        setHeight(h);
                    }
                }
            });
        };

        scaleVideo();
        window.addEventListener('resize', scaleVideo);
        return () => window.removeEventListener('resize', scaleVideo);
        // Should recalculate for videos and not for images.
    }, [videoContainerRef?.current]);

    const CarrouselItems = useMemo(() => {
        return items
            .filter((item) => item.image)
            .map((item, mediaIndex) => {
                let component = undefined;

                if (item.image.src && item.image.type === 'image') {
                    if (item.externalVideoSrc) {
                        component = (
                            <StyledVideoWrapper>
                                <StyledVideoInnerWrapper ref={videoContainerRef}>
                                    <VideoPlayerLazy
                                        key={item.externalVideoSrc}
                                        url={item.externalVideoSrc}
                                        playing={mediaIndex === activeIndex}
                                        loop={true}
                                        controls={true}
                                        width={width}
                                        height={height}
                                        volumn={0.5}
                                        config={{
                                            youtube: {
                                                playerVars: {
                                                    q: 'hd1080',
                                                },
                                            },
                                        }}
                                    />
                                </StyledVideoInnerWrapper>
                            </StyledVideoWrapper>
                        );
                    } else {
                        component = <AutoRatioImage item={item} />;
                    }
                }

                if (item.image.src && item.image.type === 'file') {
                    component = (
                        <StyledVideoWrapper>
                            <StyledVideoInnerWrapper ref={videoContainerRef}>
                                <VideoPlayerLazy
                                    key={item.image.src}
                                    url={item.image.src}
                                    playing={mediaIndex === activeIndex}
                                    muted={false}
                                    loop={true}
                                    controls={false}
                                    width={width}
                                    height={height}
                                />
                            </StyledVideoInnerWrapper>
                        </StyledVideoWrapper>
                    );
                }

                if (!component) return undefined;

                return (
                    <StyledGalleryItem key={weakKey(item)}>
                        <StyledGalleryItemInner>
                            {component}
                            <StyledCaptionWrapper>
                                <Text variant={'body'} as={'p'} style={{ color: colors.grey40 }}>
                                    {`${mediaIndex + 1}/${items.length}`}
                                </Text>
                                <Text variant={'body'} as={'p'} style={{ color: colors.white100 }}>
                                    {item.caption}
                                </Text>
                            </StyledCaptionWrapper>
                        </StyledGalleryItemInner>
                    </StyledGalleryItem>
                );
            });
    }, [items, width, height]);

    useEffect(() => {
        if (items && swiper && !swiper.destroyed && activeMediaId) {
            const activeIndex = items.findIndex((item) => item.id === activeMediaId);
            swiper?.slideTo(activeIndex, 0);
            setActiveIndex(activeIndex);
        }
    }, [swiper, items, activeMediaId]);

    return CarrouselItems?.length ? (
        <Modal
            onDismiss={hide}
            open={visible}
            autoSize={false}
            transparent={true}
            closeButtonPosition={'center'}
        >
            <StyledGalleryModal>
                {hasPrev && (
                    <StyledButtonWrapper align="left">
                        <CarouselButton direction="left" onClick={slidePrev} />
                    </StyledButtonWrapper>
                )}
                {hasNext && (
                    <StyledButtonWrapper align="right">
                        <CarouselButton direction="right" onClick={slideNext} />
                    </StyledButtonWrapper>
                )}
                <Carousel
                    config={{ slidesPerView: 1 }}
                    items={CarrouselItems}
                    prevRef={prevRef}
                    nextRef={nextRef}
                    setSwiper={setSwiper}
                    onActiveIndexChange={(index) => setActiveIndex(index)}
                />
            </StyledGalleryModal>
        </Modal>
    ) : null;
};

const AutoRatioImage = ({ item }: { item: ModalGalleryItem }) => {
    const imageRatioRef = useRef<HTMLDivElement>(null);
    const { width } = useWindowSize();

    const handleResize = () => {
        if (!imageRatioRef.current || !imageRatioRef.current.parentElement) return;
        const { width: parentWidth, height: parentHeight } =
            imageRatioRef.current.parentElement.getBoundingClientRect();
        const parentRatio = parentHeight / parentWidth;
        const dataRatio = imageRatioRef.current?.getAttribute('data-ratio') ?? '0.5625';
        const ratio = parseFloat(dataRatio);

        // Image has a wider ratio than parent container.
        if (parentRatio > ratio) {
            imageRatioRef.current.style.width = '100%';
            imageRatioRef.current.style.height = `${parentWidth * ratio}px`;
        } else {
            imageRatioRef.current.style.width = `${parentHeight / ratio}px`;
            imageRatioRef.current.style.height = '100%';
        }
    };
    useDebounce(handleResize, 100, [width]);

    const { objectFit = 'cover', objectPosition = '50% 50%', layout = 'fill' } = item?.image ?? {};

    return (
        <StyledImageWrapper>
            <StyledImageRatio ref={imageRatioRef} data-ratio={item?.image?.ratio}>
                <Image
                    disableAnimation={true}
                    src={item.image?.src}
                    alt={item.image?.name}
                    objectFit={objectFit}
                    objectPosition={objectPosition}
                    layout={layout}
                    draggable={false}
                />
            </StyledImageRatio>
        </StyledImageWrapper>
    );
};
